// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui-pro/scss/coreui.scss';

// If you want to add something do it here
@import 'custom';

// react dates overirides file
@import '_react_dates_overrides';
