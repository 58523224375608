// NOTE: the order of these styles DO matter

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: #6f6bff; //background
  color: white; //text
  border: #2f20d2;
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  color: white;
}

.CalendarDay__selected_span:hover {
  background: #2f20d2;
  border: #2f20d2;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #6f6bff; //background
  border: #2f20d2;
  color: white;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #2f20d2;
  border: #2f20d2;
}

.DateInput_input__focused {
  border: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid #2f20d2;
}
